@import url('https://fonts.googleapis.com/css2?family=Saira:wght@100;300;400&display=swap');

html {
  scroll-padding-top: 130px;
}

body {
  background-color:var(--background-color);
  color:var(--color);
  font-family: 'Saira', sans-serif;
  font-size: 1.2rem;
}

body p {
  font-family: Helvetica, sans-serif;
}

.site-header {
  background: #151515 !important;
  /*-webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  margin-bottom: 1.5rem;*/
  padding: 0.6rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-weight: 300;
  font-size: 18px;
}

.navbar-brand .logo {
  /*max-width: 145px;*/
  margin-right: 1rem;
}

.site-header .dropdown-item {
  color: #212529;
}

.site-header .dropdown-item:hover {
  color: #2d3339;
}

.site-header a:hover {
  /*border-bottom: 2px solid rgba(255, 255, 255, 0.15);*/
  text-decoration: none;
}

hr.center-separator {
  margin: 1rem auto;
}

.landing-bg{
  padding: 25rem 5rem;
  margin-top: -1.5rem;
  background-position: center;
  background-size: cover;
  color:white;
}

.landing-bg-wrap {
  padding: 25rem 5rem;
  background-position: center;
  background-size: cover;
  color:white;
  overflow: hidden;
  position: relative;
  transition: all .8s;
  background-position: center center;
  background-size: cover;
}

.landing-bg-wrap::before { 
  content:"";
  position:absolute; top:0;right:0;bottom:0;left:0;
  background:inherit;
  transition:inherit;
}

.content-wrap {
  position: relative;
}

.landing-special {
  padding: 20rem 5rem 5rem 5rem;
}

.landing-full-size {
  height: 101vh;
}

.landing-special .on-board{
  margin-top: 15rem;
  font-style: italic;
  font-weight: bold;
}

.landing-special .red-color {
  color: #ff0000;
  font-size: 2rem
}

.landing-bg p, .values p, .landing-carousel p {	
  font-family: 'Saira', sans-serif;
}

.landing-carousel{
  background-image: url('./Assets/our_products/Slider_BACKGROUND.png');
  padding: 15rem 5rem;
  margin-top: -1px;
  background-position: center;
  background-size: cover;
  color:white;
}

.landing-carousel-white{
  /*background-image: url('./Assets/our_products/Slider_BACKGROUND.png');*/
  padding: 15rem 5rem;
  margin-top: -1px;
  background-position: center;
  background-size: cover;
  color:black;
}

.landing-carousel-white .carousel-control-prev-icon, .landing-carousel-white .carousel-control-next-icon {
  filter: invert(100%);
}


.product-carousel-container {
  min-height: 500px;
}

.product-carousel-container h2{
  font-size: calc(1.975rem + 1.5vw);
}

.product-carousel-container .product-carousel-img{
  margin: auto;
  margin-top: 6rem;
  height: 32rem;
}

.product-carousel-container .product-carousel-img-ss{
  margin: auto;
  margin-top: 6rem;
  height: 26rem;
}

.landing-carousel p{
  color: white;
  font-family: 'Saira', sans-serif;
}

.slide-padding {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
}

.crimg{
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
}

.img-responsive {
  max-width: 50%;
  height: auto;
}

.pcc-centered {
  min-height: 500px;
  margin: auto;
  width: 80%;
}

.values {
  background: rgb(28, 28, 28);
  color: white;
  padding: 5rem 12rem;
  width: 70%;
}

.values h3, .values h5 {
  font-weight: bold;
  padding: 1.5rem;
}

.values p {
  font-weight: 300;
}

.landing-action{
  padding: 10rem 5rem;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://decision-wise.com/wp-content/uploads/2017/07/Employees-factory-workers-inspecting-machinery.jpg);
  background-size: cover;
  filter: brightness(95%);
  color:white;
  font-size: 1.4rem;
}

.landing-action .red-separator {
  height: 3px;
  background: rgb(255, 39, 39);
  opacity: 1;
  width: 5%;
  margin: 1rem auto;
}

.values .red-separator {
  height: 1.5px;
  background: rgb(255, 39, 39);
  opacity: 1;
  width: 5%;
  /* margin-left: 45%; */
  margin: 1rem auto;
}

.landing .subtitle {
  color: #d4d4d4;
  font-weight: normal;
}

.landing h3 {
  color: white;
  font-weight: bold;
}

.avatar {
  border-radius: 50% !important;
}

.card {
  animation-duration: .5s;
  animation-name: slidein;
}

.modal {
  animation-duration: .2s;
  animation-name: slidein;
}

.infoBox {
  padding: 10rem 5rem;
}

.infoBox h2, .infoBox h3, .infoBox .accordion-button, .our-offer h4 {
  font-weight: bold;
}

.infoBox p, .infoBox .accordion-button {
  color: black;
  font-size: 1.5rem;
  /* width: 80%; */
}

ul.diamond-deco {
  list-style: none;
  margin: 2em;
  padding: 0;
}

ul.diamond-deco li {
  margin: 0;
  margin-bottom: 1em;
  padding-left: 1.5em;
  position: relative;
}

ul.diamond-deco li::after {
  content: '';
  height: .4em;
  width: .4em;
  background: #ff0033;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: .5em;
  left: 0;
}

ul.diamond-deco .notDiamond-deco::after {
  content: none;
}

ul.diamond-deco ul.diamond-deco {
  margin : 0;
}

ul.diamond-deco ul.diamond-deco li::after {
  content: '';
  height: .4em;
  width: .4em;
  background: transparent;
  border: 2px solid #ff0033;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: .5em;
  left: 0;
}

@keyframes slidein {
  from {
      transform: translateY(-50px);
      opacity: 0;
  }

  to {
      transform: translateY(0px);
      opacity: 1;
  }
}

.un {
  margin-right: 1.5rem!important;
  display: inline-block;
  background-image: linear-gradient(#ffffff, #ffffff);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s; /*change after the size immediately*/
}

.un:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
}

.navbar .nav-item .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.3em solid #ff3728;
  border-right: 0.3em solid #ff000000;
  border-bottom: 0;
  border-left: 0.3em solid #ff000000;
  transform: rotate(270deg);
  transition: .2s ease-out;
}

.navbar .nav-item:hover .dropdown-toggle::after {
  transform: rotate(359deg);
}

.site-header .dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 1rem;
  margin: 0;
  font-size: 1rem;
  color: #ffffff;
  text-align: left;
  list-style: none;
  background-color: #151515;
  background-clip: padding-box;
  border: 0px solid rgba(0,0,0,.15);
  border-radius: 0rem;
}

.site-header .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  font-size: 1.3rem;
  color: #ffffff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.page-header-title {
  font-size: calc(1.975rem + 1.5vw);
  font-weight: bold;
}

.separator {
  font-size: calc(1.975rem + 1.5vw);
  font-weight: bold;
}

hr.red-text-bottom {
  height: 1px;
  color: red;
  opacity: 1;
  width: 23%;
}

.big-text {
  font-size: calc(3.375rem + 1.5vw)!important;
}

.landing-btn {
  font-family: 'Saira', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.9rem 5rem;
  color: white;
  border: 1.5px solid white;
  position: relative;
  text-transform: uppercase;
  outline: 0;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
	transition: all .1s ease-in-out;
  margin-top: 1rem;
  text-decoration: none;
  display: inline-block;
}

.landing-btn-home {
  font-family: 'Saira', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.9rem 4rem;
  color: white;
  border: 1.5px solid white;
  position: relative;
  text-transform: uppercase;
  outline: 0;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
	transition: all .1s ease-in-out;
  margin-top: 1rem;
  text-decoration: none;
  display: inline-block;
}

.nlanding-btn {
  font-family: 'Saira', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.9rem 5rem;
  color: black;
  border: 1.5px solid black;
  position: relative;
  text-transform: uppercase;
  outline: 0;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
	transition: all .1s ease-in-out;
}

.fill:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -2;
}

.fill:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: white;
	transition: all .13s ease-in-out;
  z-index: -1;
}

.fill:hover {
  color: black;
  text-decoration: none;
}

.fill:hover:before {
    height: 100%;
}

.nfill:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -2;
}

.nfill:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: black;
	transition: all .13s ease-in-out;
  z-index: -1;
}

.nfill:hover {
  color: white;
}

.nfill:hover:before {
    height: 100%;
}

.operating-cost {
  padding: 10rem 2rem;
}

.operating-cost .raas-scheme {
  max-width: 90%;
}

.operating-cost  .red-separator {
  height: 2px;
  background: rgb(255, 39, 39);
  opacity: 1;
  width: 8%;
  margin: 1rem auto;
}

.radial-bg {
  color: white;
  background: rgb(32,32,32);
  background: radial-gradient(circle, rgba(32,32,32,1) 0%, rgba(0,0,0,1) 100%);
}

.about-us-bloc {
    padding: 5rem;
    background-size: cover;
    background-position: center;
}

.about-us-bloc1 {
  background-image: url('./Assets/about_us/whoweare_illustration.png');
}

.about-us-bloc2 {
  background-image: url('./Assets/about_us/ourmissions_illustration.png');
}

.about-us-bloc3 {
  background-image: url('./Assets/about_us/ourvision_illustration.png');
}

.contact-box {
  background-color: #151515;
  color: white;
  padding: 5rem;
  background-size: cover;
  background-position: center;
}

.contact-form {
  padding: 5rem;
}

.contact-box .col-1 i.bi {
  font-size: 1.4rem;
  color: white;
}

/* OUR OFFER */

.our-offer-sideimage {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url('./Assets/our_offer/illustration_offer@2x.png')
}

/* LANDING BG */

#landing-about-us {
  background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url('./Assets/about_us/bg_header.png');
}

#landing-our-products {
  background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url('./Assets/our_products/ourproducts_HEADER.png');
}

#landing-digital-services {
  background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url('./Assets/digital_services/Digitalservices_HEADER.png');
}

#landing-contact-us {
  background-image: url('./Assets/contact_us/contact_HEADER@2x.png');
}

#landing-smart-systems {
  background-image: url('./Assets/smart_systems/Smartsystems_HEADER@2x.png');
}

#landing-our-offer {
  background-image: url('./Assets/our_offer/offer_BACKGROUND_HEADER@2x.png');
}

#landing-home-our-products {
  background-image: url('./Assets/home/Products_BACKGROUND@2x.png');
}

#landing-home-ds {
  background-image: url('./Assets/home/Digitalservices_BACKGROUND@2x.png');
}

#landing-home-ss {
  background-image: url('./Assets/home/Smartsystem_BACKGROUND@2x.png');
}

.landing-smart-system {
  background-image: url('./Assets/about_us/bandeau_technologies.png');
}

.landing-replacement-parts {
  background-image: url('./Assets/about_us/bandeau_remplacementparts.png');
}

.landing-opc-reduc {
  background-image: url('./Assets/our_products/bandeau_operatingcostreduction.png');
}



/*
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgb(255, 255, 255);
}*/

/* ANIMATIONS */

/* change transition duration to control the speed of fade effect */

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

@-moz-keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes zoom-in {
  0%   { transform: scale(1.0); }
  100% { transform: scale(1.2); }
}

@keyframes zoom-out {
  0%   { transform: scale(1.2); }
  100% { transform: scale(1.0); }
}

@keyframes fade-slide-in {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0px); opacity: 1!important; }
}

@keyframes fade-slide-out {
  from { transform: translateY(0px); opacity: 1!important; }
  to { transform: translateY(-50px); opacity: 0; }
}

@keyframes fade-slide-in-bottom {
  from { transform: translateY(+50px); opacity: 0; }
  to { transform: translateY(0px); opacity: 1!important; }
}

@keyframes fade-slide-out-bottom {
  from { transform: translateY(0px); opacity: 1!important; }
  to { transform: translateY(+50px); opacity: 0; }
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: .6s;
	transition-property: opacity;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item.active.carousel-item-start, .carousel-fade .carousel-item.active.carousel-item-end  {
  -webkit-animation: fadeout .6 ease-out alternate;
  -moz-animation: fadeout .6 ease-out alternate;
  animation: fadeout .6s ease-out alternate;
}

.background-zoom-in {
  -webkit-animation: zoom-in 0.75s ease-in-out alternate;
  -moz-animation: zoom-in 0.75s ease-in-out alternate;
  animation: zoom-in 0.75s ease-in-out alternate;
  transform: scale(1.2);

}

.background-zoom-out {
  -webkit-animation: zoom-out 0.75s ease-in-out alternate;
  -moz-animation: zoom-out 0.75s ease-in-out alternate;
  animation: zoom-out 0.75s ease-in-out alternate;
  transform: scale(1);
}

.title-fade-slide-in {
  -webkit-animation: fade-slide-in 0.75s ease-in-out alternate;
  -moz-animation: fade-slide-in 0.75s ease-in-out alternate;
  animation: fade-slide-in 0.75s ease-in-out alternate;
}

.title-fade-slide-out {
  -webkit-animation: fade-slide-out 0.75s ease-in-out alternate;
  -moz-animation: fade-slide-out 0.75s ease-in-out alternate;
  animation: fade-slide-out 0.75s ease-in-out alternate;
}

.background-zoom-in .landing-btn {
  -webkit-animation: fade-slide-in-bottom 0.75s ease-in-out alternate;
  -moz-animation: fade-slide-in-bottom 0.75s ease-in-out alternate;
  animation: fade-slide-in-bottom 0.75s ease-in-out alternate;
}

.background-zoom-out .landing-btn {
  -webkit-animation: fade-slide-out-bottom 0.75s ease-in-out alternate;
  -moz-animation: fade-slide-out-bottom 0.75s ease-in-out alternate;
  animation: fade-slide-out-bottom 0.75s ease-in-out alternate;
}

/** ACCORDION **/


.accordion-button:not(.collapsed) {
  background-color: #eeeeee;
}

.accordion-button:focus {
  border-color: #dcdcdc;
  box-shadow: 0 0 0 0.25rem #f443362b;
}

.accordion-button:not(.collapsed)::after {
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff1100'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


/** FOOTER **/

.footermain {
  margin-top: 5rem;
  border-top: 0.4rem solid rgb(232, 16, 16);;
}

.bd-footer-links {
  margin-bottom: 0;
}

.bd-footer-links li {
  display: inline-block;
}

.bd-footer-links li+li {
  margin-left: 2rem;
}

.bd-footer a , .bd-footer small {
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.bd-footer a:hover {
  color: rgb(57, 57, 57);
  text-decoration: none;
}

.bd-footer div.main-ft {
  padding: 2.2rem;
}

/** MOBILE AND TABLET **/

@media all and (max-device-width: 480px)
{
  .product-carousel-container .product-carousel-img, .product-carousel-container .product-carousel-img-ss {
    height: 6rem;
  }
}

@media only screen and (max-width: 767px) {
  .background-zoom-in {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
    background-size: cover;
  }
  
  .background-zoom-out {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
    background-size: cover;
  }

  .product-carousel-container .product-carousel-img, .product-carousel-container .product-carousel-img-ss {
    height: 12rem;
  }

  .navbar-dark .navbar-nav .nav-link {
    font-size: 1.3rem;
  }

  .navbar-brand .logo {
    max-width: 145px;
    margin-right: 1.5rem;
  }

  .landing-full-size {
    height: auto;
  }

  .landing-btn {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }

  .bd-footer-links {
    padding: 0;
  }

  .bd-footer-links li {
    display: block;
  }

  .bd-footer-links li+li {
    margin-left: 0;
    margin-top: .3rem;
  }

  .infoBox {
    padding: 5rem 5rem;
  }

  .values {
    padding: 2rem 2rem;
    width: 90%;
  }

  .landing-bg {
    padding: 15rem 2rem;
  }

  .landing-carousel {
    padding: 7rem 5rem;
    margin-top: -1px;
  }

  .footermain {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1340px) and (min-width: 767px){
  .navbar-dark .navbar-nav .nav-link {
    font-size: 1rem;
  }

  .navbar-brand .logo {
    max-width: 145px;
  }
}

@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display:block; height: 0; opacity: 0; transition: opacity .1s ease-out;}
	.navbar .nav-item:hover .dropdown-menu{ height: auto; opacity: 1; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}