@import url('https://fonts.googleapis.com/css2?family=Saira:wght@100;300;400&display=swap');

/* UNDER CONSTRUCTION */

body p {
  font-family: Helvetica, sans-serif;
}

html, body { 
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Saira', sans-serif;
}

.maintenance {
  padding: 4rem 6rem
}

.maintenance p{
  font-family: 'Saira', sans-serif;
}

.maintenance #logo {
  width: 17rem;
  max-width: 100%;
  margin: 0 auto;
}

a {
  color: #c4191d;
  text-decoration: underline;
}

a:hover {
  color: #d23c3e;
  text-decoration: underline;
}

@media all and (max-device-width: 480px)
{
  .maintenance {
    font-family: 'Saira', sans-serif;
    padding: 2rem 2rem
  }
}

@media (min-width:961px)  {
  .h3, h3 {
    font-size: 1.8rem!important;
}

  .maintenance p {
    font-family: 'Saira', sans-serif;
    font-size: 1.3rem!important;
}
}

@media (min-width:1079px)
{
  .maintenance {
    margin-top: 3rem!important;
  }

  .maintenance #logo {
    width: 25rem;
  }

  .maintenance p {
    font-family: 'Saira', sans-serif;
    font-size: 1.7rem!important;
}

.h3, h3 {
  font-size: 2.5rem;
  }
}